


.headertitle__container {
    font-family: var(--primary-font);
    text-align: center;
}

.container__title{
    text-align: center;
}


.grey__line{
    display: none;
}

@media(min-width:468px){
    .headertitle__container{
        display: flex;
        align-items: center;
        width: 90%;
        justify-content: center;
        font-size: 1rem;
    }

    .grey__line{
        display: block;
        width: 25%;
        background-color: rgb(214, 214, 214);
        height: 1px;
    }

    .container__title{
        margin: 0 1.3rem;
    }
}

@media(min-width:768px){
    .headertitle__container {
        font-size: 1.2rem;
        margin-block: 2rem;
        
    }
}

@media(min-width:768px){
    
}