

.services__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 3rem;
}

.services__card__container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.services__card1{
    width: 300px;
    height: 300px;
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-block: 1rem;
    cursor: pointer;
    transition: 0.3s ease;
}

.service__text__container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 1rem;
}

.services__container a{
    text-decoration: none;
    color: inherit;
}

.services__card1:hover{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services__card1:active {
    box-shadow: none;
    transform-origin: center;
    transform: scale(0.98);
  }


.card__header{
    font-size: 1.4rem;
    font-family: var(--primary-font);
}

.card__description{
    font-family: 'Pontano Sans';
    padding: 1rem;
}

.service__text__container ul{
    list-style: none;
    margin: 0;
}

.services__card1:nth-child(2) .card__header{
    margin: 0;
    font-size: 1.5rem;
}

.service__text__container ul li{
    margin-block: 0.5rem;
}



@media(min-width:905px){
    .services__header{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .services__card__container{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }
}

