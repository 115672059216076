.boiler__service__hero__container{
    position: relative;
    width: 100%;
}

.boiler__service__image__container{
    position: relative;
    width: 100%;
    
    overflow: hidden;
}

.boiler__service__image__container img{
    width: 100%;
    object-fit: cover;
}


.boiler__service__title__container{
    width: 100%;
    position: absolute;
    top: 120px;
    text-align: center;
}

.boiler__service__title__container h1{
    font-size: 2.3rem;
    font-family: var(--primary-font);
    color: white;
}

.boiler__service__text__container{
    padding: 2rem 3.5rem;
}

.boilder__service__header{
    font-family: var(--primary-font);
    text-align: center;
}

.header__description{
    font-family: 'Pontano Sans';
}

.boilder__service__subheader{
    font-family: var(--primary-font);
    margin-block: 1rem;

}

.boiler__service__text__container ul{
    font-size: 1.rem;
    font-family: 'Pontano Sans';
    margin-left: 2rem;
}

.boiler__service__text__container ul li ul{
    margin-top: 0.5rem;
}

.boilder__service__header{
    text-align: center;
}

@media(min-width:768px){
    .boiler__service__image__container{
        max-height: 400px;
        height: 55vh;
    }

    .boiler__service__image__container img{
        height: 100%;
    }

    .boiler__service__title__container{
        position: absolute;
        top: 0;
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .boiler__service__title__container .boiler__service__title{
        font-size: 4rem;
    }
    .boilder__service__header{
        font-size: 2.5rem;
    }

    .boilder__service__subheader{
        font-size: 2.2rem;
    }

    .boiler__service__text__container ul{
        font-size: 1.2rem;
    }


}


@media(min-width:905px){

    .service__contactus__button{
        width: 200px;
        height: 50px;
        font-size: 1rem;
    }

    .boiler__service__container .boiler__service__image__container{
        height: 90vh;
        max-height: 90vh;
    }

    .boiler__service__container .boiler__service__title__container{
        height: 90vh;
    }

    .boiler__service__container .boiler__service__title__container h1{
        font-size: 6rem;
    }

    .boiler__service__text__container{
        margin: 4rem auto;
        padding: 2rem;
        text-align: left;
        width: 78%;
    }

    .boiler__service__text__container h1{
        font-size: 2.5rem;
        text-align: center;
    }

    
}