.client__page__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client__page__header{
    font-family: var(--primary-font);
    margin-block: 2rem;
}

.client__icon__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row__icon__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.icon__container{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

/* resizing SPH logo */
.row__icon__container:nth-child(1) .icon__container:nth-child(4) img{
    width: 200px;
}


/* resizing Merlion logo */
.row__icon__container:nth-child(2) .icon__container:nth-child(2) img{
    width: 200px;
    margin-block: 1rem;
}

/* resizing Edwards logo */
.row__icon__container:nth-child(2) .icon__container:nth-child(3) img{
    width: 150px;
    margin-block:2rem;
}

/* resizing Heng Guan logo */
.row__icon__container:nth-child(2) .icon__container:nth-child(4) img{
    
    margin-block:2rem;
}

.row__icon__container:nth-child(3) .icon__container:nth-child(1) img{
    margin-block: 2rem;
    width: 200px;
}

.row__icon__container:nth-child(3) .icon__container:nth-child(2) img{
    margin-block: 2rem;
    width: 200px;
}

.row__icon__container:nth-child(3) .icon__container:nth-child(3) img{
    margin-block: 2rem;
    width: 200px;
}

.row__icon__container:nth-child(3) .icon__container:nth-child(4) img{
    margin-block: 2rem;
    width: 200px;
}



.icon__container img{
    width: 300px;
}

.icon__container p{
    display: none;
}

.client__title__container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-block: 2rem;
}

@media(min-width: 768px){
    .row__icon__container{
        display: flex;
        flex-direction: row;
        padding: 1rem;
    }

    

    @media(min-width: 905px){
    
        .icon__container img{
            width: 100%;
        }

        
    }

    

    
}