@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Pontano+Sans:wght@400;500;600;700&display=swap');

:root{
  --primary-font: 'Montserrat';
  --secondary-font: sans-serif;
}


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}