.aboutus__hero__container{
    position: relative;
    width: 100%;
}

.aboutus__image__container{
    width: 100%;
}

.aboutus__image__container img{
    width: 100%;
}


.aboutus__title__container{
    width: 100%;
    position: absolute;
    top: 120px;
    text-align: center;
}

.aboutus__title__container h1{
    font-size: 2.3rem;
    font-family: var(--primary-font);
    color: white;
}

.aboutus__text__container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0 2rem 2rem 2rem;
}

.aboutus__text__container__header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 2rem;
}

.aboutus__text__container__subheader{
    font-family: var(--primary-font);
    font-size: 1.4rem;
    margin-top: 2rem;
}

.aboutus__text__container__description{
    font-family: 'Pontano Sans';
    font-size: 1rem;
    margin-top: 10px;
}

@media(min-width:468px){
    .aboutus__title__container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        padding: 0;
       
        
    }

    .aboutus__title__container .aboutus__title{
        font-size: 4rem;
    }
}

@media(min-width:768px){
    .aboutus__hero__container{
        height: 50vh;
        overflow: hidden;
    }  

    .aboutus__text__container__subheader{
        font-size: 1.8rem;
    }

    .aboutus__text__container__subheader,.aboutus__text__container__description{
        margin-left: 1rem;
    }

    .aboutus__image__container img{
        object-fit: contain;
    }
 
}

@media(min-width:906px){
    .aboutus__hero__container{
        height: 80vh;
    }

    .aboutus__image__container{
        height: 100%;
    }

    .aboutus__page__container .aboutus__title{
        font-size: 5rem;
    }


    .aboutus__text__container{
        text-align: center;
        margin: 0 auto;
    }

    .aboutus__text__container__description{
        
        
        font-size: 1.3rem;
    }
}