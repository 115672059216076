.map__container{
    padding: 2rem;
}

.map__container .google__map{
    border: none;
    width: 100%;
    height: 400px;
}


@media(min-width:905px){
    .map__container .google__map{
        height: 70vh;
    }
}