

.navbar__container{
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(6, 120, 6);
}


.logo__container{
    width: 15%;
    padding: 1rem;
    min-width: 150px;
}


.navbar__logo{
    width: 100%;
}

.link__container ul{
    display: flex;
    list-style: none;
    padding-right: 1rem;
}

.link__container ul a{
    font-family: var(--primary-font);
    font-size: 16px;
    text-decoration: none;
    color: white;
    font-weight: 700;
    margin: 0 1rem;
    padding: 8px 15px;
    text-transform: uppercase;
    transition: opacity 0.3s ease-in-out
}

nav li a:hover {
    opacity: 0.7;
}

.mobile__navbar,.menu-icon{
    display: none;
}



.link__container{
    display: none;
}

.menu-icon{
    display: block;
    font-size: 30px;
    margin-right: 1.5rem;
    color: white;
    cursor: pointer;
}

.mobile__navbar{
    display: block;
    position: fixed;
    top: 0;
    right: -300px;
    background-color: white;
    width: 300px;
    height: 100vh;
    transition: right 0.3s ease;
    z-index: 10;
}

.mobile__navbar.open{
    right: 0;
}
.close__icon__container{
    font-size: 40px;
    text-align: right;
}

.close__icon{
    margin-top: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}

.mobile__navbar ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mobile__navbar ul a{
    font-family: var(--primary-font);
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    color: #a3a3a3;
}

.mobile__navbar ul a:hover{
    opacity: 1;
}

.mobile__navbar ul li{
    margin-block: 1rem;
    margin-left: 2rem;
}

@media(min-width: 768px){
    .navbar__container{
        height: 62px;
    }

    .logo__container{
        width: 25%;
        padding: 1rem;
        min-width: 150px;
    }
}

@media(min-width:905px){
    .navbar__container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .menu-icon{
        display: none;
    }
    
    .mobile__navbar{
        display: none;
    }

    .link__container{
        display: block;
    }
    .logo__container{
        width: 15%;
    }

    .navbar__container{
        height: 70px;
    }

    .link__container ul a{
        font-size: 16px;
    }
}