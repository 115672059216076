.contactus__left__page{
    padding: 3rem 2rem 0 2rem;
}

.contactus__right__page{
    text-align: center;
    padding-bottom: 4rem;
}

.contactus__right__page,.home__contactus__page{
    background-color: #dddddd;
}

.company__name{
    font-family: var(--primary-font);
}

.company__address{
    font-family: 'Pontano Sans';
    margin-block: 5px;
    font-size: 16px;
}

.company__address:nth-child(2){
    margin-top: 1rem;
}

.company__address:nth-child(6){
    margin-bottom: 1rem;
}

.contactus__form__group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-block: 1rem;
}

.contactus__form__group label{
    font-family: 'Pontano Sans';
    font-size: 13px;
    font-weight: bolder;
}

.contactus__form__group input{
    width: 100%;
    height: 35px;
    margin-block: 3px;
    border: none;
    outline: none;
}

.contactus__form__group textarea{
    width: 100%;
    margin-block: 5px;
    border: none;
    outline: none;
    height: 300px;
}


.contactus__left__page button{
    
    border: none;
    height: 45px;
    width: 85%;
    cursor: pointer;
    background-color: #1565c0;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    align-self: center;
    
}

.success-notification, .error-notification{
    font-family: 'Pontano Sans';
    font-size: 18px;
    font-weight: bolder;
    margin-left: 2rem;
    margin-top: 1rem;
}

.success-notification{
    color: green;
}

.error-notification{
    color: red;
}


@media(min-width:768px){
    .contactus__right__page {
        text-align: left;
    }
    
    .contactus__right__page button{
        margin-left: 2rem;
        width: 30%;
    }

    .home__contactus__page .company__name{
        font-size: 2.5rem;
    }

    .company__address{
        font-size: 1.1rem;
    }

    

}

@media(min-width:905px){
    .home__contactus__page{
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-block: 4rem;
    }

    .contactus__left__page{
        width: 50%;
        margin-left: 2rem;
    }

    .contactus__right__page{
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        margin-top: 2rem;
        padding: 2rem;
        width: 70%;
    }

    

}