

.home__header__container{
    position: relative;
    width: 100%;
}

.header__image__container{
    width: 100%;
    height: 55vh;
    overflow: hidden;
    min-height: 450px;
}

.home__header__container img{
    height: 55vh;
    min-height: 450px;
}

.header__title__container{
    position: absolute;
    top: 60px;
    text-align: center;
    color: white;
    font-family: var(--primary-font);
    text-transform: uppercase;
    margin: 0 1rem;
}

.header__subtitle{
    font-size: 1.5rem;
}

.header__title{
    font-size: 1.9rem;
    margin-block: 1rem;
}

.header__description{
    font-family: var(--secondary-font);
    margin-block: 1rem;
    text-transform: none;
}

.header__button{
    width: 180px;
    height: 60px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400px;
    text-transform: uppercase;
    color: white;
    background-color: #6dab3c;
    margin-block: 2rem;
}

.header__button:hover{
    background-color: #5f9434;
}


@media(min-width:468px){
    .header__title__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
        padding: 0 1rem;
    
        
    }

    .home__header__container img{
        width: 100%;
    }

    .header__subtitle{
        font-size: 1.6rem;
    }
    
    .header__title{
        font-size: 1.9rem;
        margin-block: 1rem;
    }
    
    .header__description{
        font-family: var(--secondary-font);
        margin-block: 1rem;
        text-transform: none;
    }

    .header__button{
        align-self: center;
    }
}

@media(min-width: 768px){
    .header__subtitle{
        font-size: 2rem;
    }
    
    .header__title{
        font-size: 2.5rem;
        
    }
    
    .header__description{
        font-family: var(--secondary-font);
        margin-block: 1rem;
        font-size: 1.2rem;
        text-transform: none;
    }

    .header__button{
        align-self: center;
    }
}

@media(min-width:905px){
    .home__header__container{
        height: 100vh;
    }
    .header__image__container{
        height: 100%;
    }
    .header__image__container img{
        height: 100%;
    }
    .header__title{
        font-size: 5rem;
    }
    .header__subtitle{
        font-size: 2rem;
    }
}

@media(min-width:1024px){
    
}
