.inquiry__image__container{
    margin-top: 1rem;
    width: 100%;
}

.home__inquiry__container{
    position: relative;
}


.inquiry__image__container img{
    width: 100%;
}

.inquiry__title__container{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    text-align: center;
    height: 100%;
}

.inquiry__title,.inquiry__subtitle{
    font-family: var(--primary-font);
    color: white;
}

.inquiry__title{
    font-size: 1.5rem;
    padding: 0 1rem;
}

.inquiry__subtitle{
    margin-block: 0.2rem;
}

.inquiry__subtitle:nth-child(3){
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

.inquiry__button{
    width: 250px;
    height: 60px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400px;
    text-transform: uppercase;
    color: white;
    background-color: #6dab3c;
    
}

.inquiry__button:hover{
    background-color: #5f9434;
}


@media(min-width:468px){
    .inquiry__title__container{
    
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 60px;
        text-align: center;
    }

    .inquiry__title{
        font-size: 2.8rem;
    }

    .inquiry__subtitle{
        font-size: 2.5rem;
    }

    .inquiry__subtitle:nth-child(3){
        font-size: 2.5rem;
    }

    
}

@media(min-width:768px){
    .home__inquiry__container{
        height: 50vh;
    }

    .inquiry__image__container{
        height: 100%;
        overflow: hidden;
    }

    .inquiry__image__container img{
        height: 100%;
    }

    .inquiry__title__container{
        position: absolute;
        top: -5px;
        height: 100%;
    }

    .inquiry__title,.inquiry__subtitle:nth-child(3){
        font-size: 2rem;
    }

    @media(min-width: 906px){

        .inquiry__image__container{
            position: relative;
            overflow: hidden;
        }

        .inquiry__image__container img{
            position: absolute;
            height: auto;
            top: -180px;
            
        }
    }
}
