.otherservices__card__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 4rem;
}


.otherservices__container{
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    cursor: pointer;
}

.otherservices__hero__card{
    position: relative;
    width: 100%;
    border: 0px;
    overflow: hidden;
}


.otherservices__card__image{
    width: 100%;
    border: none;
    transition: transform 0.8s ease;
}

.otherservices__hero__card:hover .otherservices__card__image{
    transform: scale(1.1); /* Zoom in by 10% when hovered */
}

.otherservices__card__text__container{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.8s ease;
}

.otherservices__hero__card:hover .card__text__container{
    background-color: rgba(0, 0, 0, 0.2);
}

.otherservices__card__title{
    font-size: 2rem;
    font-family: var(--primary-font);
    color: white;
}


@media(min-width:768px){
    .otherservices__container{
        width: 70%;
    }
}

@media(min-width:905px){
    .otherservices__container{
        width: 30%;
    }
}



