.contact__hero__container{
    position: relative;
    width: 100%;
}

.contact__image__container{
    width: 100%;
    max-height: 300px;
    overflow: hidden;
}

.contact__image__container img{
    width: 100%;
    
}


.contact__title__container{
    width: 100%;
    position: absolute;
    top: 120px;
    text-align: center;
}

.contact__title__container h1{
    font-size: 2.3rem;
    font-family: var(--primary-font);
    color: white;
}

.service__contactus__button{
    margin-block: 1.2rem;
    width: 120px;
    height: 40px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
}

.service__contactus__button:hover{
    background-color: white;
    color: black;
}

@media(min-width:768px){

    .contact__hero__container{
        position: relative;
        height: 52vh;
    }

    .contact__image__container{
        height: 100%;
        max-height: 380px;
    }

    .contact__title__container{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        height: 100%;
    }

    .contact__title__container .contact__title{
        font-size: 4rem;
    }
}



@media(min-width: 905px){
    .contact__hero__container{
        height: 90vh;
    }

    .contact__image__container{
        height: 90vh;
        max-height: 90vh;
    }

    .contactus__container{
        background-color: #dddddd;
    }

    .contact__title__container .contact__title{
        font-size: 6rem;
    }
}