.footer{
    padding: 3rem 2rem;
    text-align: center;
}


.footer p{
    font-size: 14px;
    font-family: var(--secondary-font);
    color: #868686;
}