.gallery__container{
    width: 100%;
    margin-block: 5rem;
}

.title__container{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-block: 2rem;
}

.gallery{
    width: 80%;
    margin: 0 auto;
}

.carousel .carousel-status{
    font-family: 'Pontano Sans';
    font-size: 20px;
    margin: 1rem;
}

.carousel .slide .legend{
    font-family: 'Pontano Sans';
    font-size: 20px;
}

.carousel div img {
    max-width: 100%;
    max-height: 600px; /* Adjust the maximum height as needed */
     /* Maintain aspect ratio while covering the available space */
}

.carousel .thumb{
    display: none;
}


@media(min-width: 905px){
    .carousel div img {
        width: 100%;
        height: 100%;
        max-height: 600px; /* Adjust the maximum height as needed */
        object-fit: cover; /* Maintain aspect ratio while covering the available space */
    }
}